import React, { useState, useEffect } from 'react';

const CONTRACT_ACCOUNT = 'leefadsplace';
const TOKEN_CONTRACT = 'leefmaincorp';
const TIME_UNIT = 1000000; // 1 day in the contract's time units
const LEEF_PER_DAY = 1000000; // 1,000,000 LEEF per day

const SubmitAd = ({ session, theme, onSubmit, onAdSubmitted }) => {
  const [content, setContent] = useState('');
  const [redirectUrl, setRedirectUrl] = useState('');
  const [targetUrl, setTargetUrl] = useState('');
  const [payment, setPayment] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [pendingAds, setPendingAds] = useState([]);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (session) {
      checkAdminStatus();
      if (isAdmin) {
        fetchPendingAds();
      }
    }
  }, [session, isAdmin]);

  useEffect(() => {
    setImageError(false);
  }, [targetUrl]);

  const checkAdminStatus = async () => {
    try {
      const result = await session.client.v1.chain.get_table_rows({
        json: true,
        code: CONTRACT_ACCOUNT,
        scope: CONTRACT_ACCOUNT,
        table: 'admins',
        lower_bound: session.actor,
        upper_bound: session.actor,
        limit: 1,
      });
      setIsAdmin(result.rows.length > 0 && result.rows[0].is_admin);
    } catch (error) {
      console.error('Error checking admin status:', error);
    }
  };

  const fetchPendingAds = async () => {
    try {
      const result = await session.client.v1.chain.get_table_rows({
        json: true,
        code: CONTRACT_ACCOUNT,
        scope: CONTRACT_ACCOUNT,
        table: 'ads',
        limit: 100,
      });
      setPendingAds(result.rows.filter(ad => !ad.is_approved));
    } catch (error) {
      console.error('Error fetching pending ads:', error);
    }
  };

  const calculateDuration = (leefAmount) => {
    return Math.floor(leefAmount / LEEF_PER_DAY * TIME_UNIT);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!session) {
      setError("Please login first");
      return;
    }
    if (!content || !redirectUrl || !targetUrl || !payment) {
      setError("Please fill in all fields");
      return;
    }
    if (isNaN(payment) || payment <= 0) {
      setError("Please enter a valid LEEF amount");
      return;
    }
    setIsSubmitting(true);
    setError('');

    try {
      const paymentAmount = `${parseFloat(payment).toFixed(4)} LEEF`;
      const durationInUnits = calculateDuration(parseFloat(payment));
      const combinedContent = `${content} ${redirectUrl}`;

      const actions = [
        {
          account: TOKEN_CONTRACT,
          name: 'transfer',
          authorization: [{ actor: session.actor, permission: 'active' }],
          data: {
            from: session.actor,
            to: CONTRACT_ACCOUNT,
            quantity: paymentAmount,
            memo: 'Ad payment',
          },
        },
        {
          account: CONTRACT_ACCOUNT,
          name: 'submitad',
          authorization: [{ actor: session.actor, permission: 'active' }],
          data: {
            advertiser: session.actor,
            content: combinedContent,
            target_url: targetUrl,
            payment: paymentAmount,
            duration: durationInUnits,
          },
        }
      ];

      console.log('Submitting ad actions:', actions);
      const result = await session.transact({ actions });
      console.log('Ad submitted successfully:', result);

      onSubmit();
      setContent('');
      setRedirectUrl('');
      setTargetUrl('');
      setPayment('');
      
      // Notify parent component that a new ad has been submitted
      if (onAdSubmitted) {
        onAdSubmitted();
      }
    } catch (error) {
      console.error('Ad submission failed:', error);
      handleTransactionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleApproveAd = async (adId) => {
    setIsSubmitting(true);
    setError('');

    try {
      const approveAdAction = {
        account: CONTRACT_ACCOUNT,
        name: 'approvead',
        authorization: [{ actor: session.actor, permission: 'active' }],
        data: {
          ad_id: adId,
          admin: session.actor,
        },
      };

      console.log('Approving ad:', approveAdAction);
      const approveResult = await session.transact({ actions: [approveAdAction] });
      console.log('Ad approved successfully:', approveResult);

      fetchPendingAds();
    } catch (error) {
      console.error('Ad approval failed:', error);
      handleTransactionError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleTransactionError = (error) => {
    if (error.message.includes('Unable to open the popup window')) {
      setError('Unable to open the signing window. Please ensure popups are allowed for this site and try again. If you\'re using a popup blocker, you may need to disable it for this site.');
    } else {
      setError(`Transaction failed: ${error.message}`);
    }
  };

  const containerStyle = {
    backgroundColor: theme.cardBackground,
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    marginBottom: '20px',
  };

  const headingStyle = { 
    color: theme.textColor, 
    marginBottom: '20px',
    fontSize: '24px',
    textAlign: 'center'
  };

  const subHeadingStyle = {
    ...headingStyle,
    fontSize: '20px',
    marginTop: '20px',
  };

  const errorStyle = {
    backgroundColor: '#ffebee',
    color: '#c62828',
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '20px',
    fontSize: '14px',
  };

  const labelStyle = { 
    display: 'block', 
    marginBottom: '5px', 
    color: theme.textColor,
    fontSize: '14px'
  };

  const inputStyle = {
    width: '100%',
    padding: '10px',
    borderRadius: '5px',
    border: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.cardBackground,
    color: theme.textColor,
    fontSize: '14px',
  };

  const textareaStyle = {
    ...inputStyle,
    minHeight: '100px',
    resize: 'vertical',
  };

  const buttonStyle = {
    width: '100%',
    padding: '12px',
    backgroundColor: isSubmitting ? theme.borderColor : theme.buttonBackground,
    color: theme.buttonText,
    border: 'none',
    borderRadius: '5px',
    cursor: isSubmitting ? 'not-allowed' : 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s ease',
  };

  const adCardStyle = {
    backgroundColor: theme.highlightBackground,
    padding: '15px',
    borderRadius: '5px',
    marginBottom: '15px',
  };

  const imageStyle = {
    width: '100%',
    maxHeight: '200px',
    objectFit: 'contain',
    marginBottom: '10px',
    borderRadius: '5px',
  };

  const adPreviewStyle = {
    marginTop: '30px',
    marginBottom: '30px',
  };

  const renderAdPreview = () => {
    const durationDays = payment ? (parseFloat(payment) / LEEF_PER_DAY).toFixed(2) : 0;
    return (
      <div style={adPreviewStyle}>
        <h4 style={subHeadingStyle}>Ad Preview</h4>
        <div style={adCardStyle}>
          {targetUrl && !imageError ? (
            <img 
              src={targetUrl} 
              alt="Ad Preview" 
              style={imageStyle}
              onError={() => setImageError(true)}
            />
          ) : (
            <div style={{
              ...imageStyle,
              backgroundColor: theme.backgroundSecondary,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: theme.textColor
            }}>
              {targetUrl ? 'Image unavailable' : 'No image URL provided'}
            </div>
          )}
          <p style={{ color: theme.textColor, marginBottom: '10px' }}>
            {content || 'No content provided'}
          </p>
          <p style={{ color: theme.textColor, marginBottom: '10px' }}>
            Redirect URL: <a href={redirectUrl} target="_blank" rel="noopener noreferrer">{redirectUrl || 'Not specified'}</a>
          </p>
          <p style={{ color: theme.textColor, marginBottom: '5px' }}>
            Payment: {payment ? `${payment} LEEF` : 'Not specified'}
          </p>
          <p style={{ color: theme.textColor, marginBottom: '15px' }}>
            Duration: {durationDays > 0 ? `${durationDays} day(s)` : 'Not specified'}
          </p>
        </div>
      </div>
    );
  };

  const renderAdminSection = () => {
    if (!isAdmin) return null;

    return (
      <div style={{ marginTop: '30px' }}>
        <h3 style={headingStyle}>Admin Panel - Pending Ads</h3>
        {pendingAds.length === 0 ? (
          <p style={{ color: theme.textColor }}>No pending ads at the moment.</p>
        ) : (
          pendingAds.map(ad => (
            <div key={ad.id} style={adCardStyle}>
              <img 
                src={ad.target_url} 
                alt="Ad Image" 
                style={imageStyle}
                onError={(e) => e.target.style.display = 'none'}
              />
              <p style={{ color: theme.textColor, marginBottom: '10px' }}>
                <strong>Content:</strong> {ad.content}
              </p>
              <p style={{ color: theme.textColor, marginBottom: '10px' }}>
                <strong>Payment:</strong> {ad.payment}
              </p>
              <p style={{ color: theme.textColor, marginBottom: '15px' }}>
                <strong>Duration:</strong> {(ad.duration / TIME_UNIT).toFixed(2)} day(s)
              </p>
              <button
                onClick={() => handleApproveAd(ad.id)}
                disabled={isSubmitting}
                style={{
                  ...buttonStyle,
                  opacity: isSubmitting ? 0.7 : 1,
                  cursor: isSubmitting ? 'not-allowed' : 'pointer'
                }}
              >
                {isSubmitting ? 'Processing...' : 'Approve Ad'}
              </button>
            </div>
          ))
        )}
      </div>
    );
  };

  return (
    <div style={containerStyle}>
      <h3 style={headingStyle}>Submit New Ad</h3>
      {error && (
        <div style={errorStyle}>
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="content" style={labelStyle}>
            Ad Content
          </label>
          <textarea
            id="content"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Enter your ad content here"
            required
            style={textareaStyle}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="redirectUrl" style={labelStyle}>
            Redirect URL
          </label>
          <input
            id="redirectUrl"
            type="url"
            value={redirectUrl}
            onChange={(e) => setRedirectUrl(e.target.value)}
            placeholder="https://example.com"
            required
            style={inputStyle}
          />
        </div>
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="targetUrl" style={labelStyle}>
            Photo URL
          </label>
          <input
            id="targetUrl"
            type="url"
            value={targetUrl}
            onChange={(e) => setTargetUrl(e.target.value)}
            placeholder="https://example.com/image.png"
            required
            style={inputStyle}
          />
        </div>
        <div style={{ marginBottom: '20px' }}>
          <label htmlFor="payment" style={labelStyle}>
            Payment (LEEF)
          </label>
          <input
            id="payment"
            type="number"
            value={payment}
            onChange={(e) => setPayment(e.target.value)}
            placeholder="Enter amount"
            required
            step="0.0001"
            min="0.0001"
            style={inputStyle}
          />
          <p style={{ color: theme.textColor, fontSize: '14px', marginTop: '5px' }}>
            Ad duration: {payment ? `${(parseFloat(payment) / LEEF_PER_DAY).toFixed(2)} day(s)` : '0 days'}
          </p>
        </div>
        {renderAdPreview()}
        <button 
          type="submit" 
          disabled={isSubmitting}
          style={buttonStyle}
        >
          {isSubmitting ? 'Submitting...' : 'Submit Ad'}
        </button>
      </form>
      {renderAdminSection()}
    </div>
  );
};

export default SubmitAd;
