import React, { useState, useEffect } from 'react';
import { APIClient } from "@wharfkit/antelope";

const LeefAd = ({ theme, refreshTrigger }) => {
  const [ad, setAd] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    fetchRandomAd();
  }, [refreshTrigger]);

  const extractUrl = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const matches = text.match(urlRegex);
    return matches ? matches[0] : '';
  };

  const fetchRandomAd = async () => {
    try {
      const client = new APIClient({ url: "https://wax.greymass.com" });
      const result = await client.v1.chain.get_table_rows({
        json: true,
        code: 'leefadsplace',
        scope: 'leefadsplace',
        table: 'ads',
        limit: 100 // Ensure we get a good sample of ads
      });
      console.log('All ads:', result.rows);
      
      const now = new Date().toISOString();
      const activeAds = result.rows.filter(ad => {
        const isActive = ad.is_active === 1;
        const isApproved = ad.is_approved === 1;
        const isWithinTimeRange = ad.end_time > now;
        
        console.log(`Ad ${ad.id}:`, {
          is_active: ad.is_active,
          is_approved: ad.is_approved,
          start_time: ad.start_time,
          end_time: ad.end_time,
          current_time: now,
          isActive,
          isApproved,
          isWithinTimeRange
        });

        return isActive && isApproved && isWithinTimeRange;
      });

      console.log('Filtered active ads:', activeAds);

      if (activeAds.length > 0) {
        const randomAd = activeAds[Math.floor(Math.random() * activeAds.length)];
        console.log('Selected ad:', randomAd);
        const url = extractUrl(randomAd.content);
        const contentWithoutUrl = randomAd.content.replace(url, '').trim();
        setAd({...randomAd, content: contentWithoutUrl, url: url});
        setImageError(false);
      } else {
        console.log('No active ads found');
        setAd(null);
      }
    } catch (error) {
      console.error('Error fetching ads:', error);
      setAd(null);
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  if (!ad) {
    return (
      <div style={{
        width: '100%',
        maxWidth: '300px',
        height: '200px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.highlightBackground,
        color: theme.textColor,
        borderRadius: '10px',
      }}>
        No active ads available
      </div>
    );
  }

  return (
    <div style={{
      position: 'relative',
      width: '100%',
      maxWidth: '300px',
      margin: '0 auto',
    }}>
      <div style={{
        position: 'absolute',
        top: '10px',
        left: '10px',
        backgroundColor: 'rgba(0,0,0,0.6)',
        color: '#fff',
        padding: '5px 10px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: 'bold',
        zIndex: 1
      }}>
        Advertisement
      </div>
      <a 
        href={ad.url}
        target="_blank" 
        rel="noopener noreferrer"
        style={{
          display: 'block',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'transform 0.3s ease-in-out',
          backgroundColor: theme.highlightBackground,
          transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!imageError ? (
          <img 
            src={ad.target_url} 
            alt="Advertisement"
            onError={handleImageError}
            style={{
              width: '100%',
              height: 'auto',
              display: 'block',
            }}
          />
        ) : (
          <div style={{
            width: '100%',
            height: '200px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: theme.highlightBackground,
            color: theme.textColor,
          }}>
            Ad Content
          </div>
        )}
        {isHovered && (
          <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            color: '#fff',
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            transition: 'opacity 0.3s ease-in-out',
          }}>
            <p style={{
              fontSize: '1rem',
              lineHeight: '1.5',
              marginBottom: '15px',
            }}>
              {ad.content}
            </p>
            <span style={{
              display: 'inline-block',
              padding: '10px 20px',
              backgroundColor: theme.primaryColor,
              color: '#ffffff',
              textDecoration: 'none',
              borderRadius: '5px',
              fontWeight: 'bold',
              textTransform: 'uppercase',
              letterSpacing: '1px',
            }}>
              Learn More
            </span>
          </div>
        )}
      </a>
      <div style={{
        marginTop: '10px',
        fontSize: '12px',
        color: theme.textColor,
        textAlign: 'center',
      }}>
        #leefAd #sponsoredContent
      </div>
    </div>
  );
};

export default LeefAd;
