import React, { useState, useEffect } from 'react';
import { createSessionKit } from '@wharfkit/starter-wax';
import { APIClient } from "@wharfkit/antelope";
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import axios from "axios";
import LeefAd from './LeefAd';
import SubmitAd from './SubmitAd';
import AdminPanel from './AdminPanel';
import TelegramUserID from './TelegramUserID';
const App = () => {
  
  const [sessionKit, setSessionKit] = useState(null);
  const [session, setSession] = useState(null);
  const [accountInfo, setAccountInfo] = useState({ accountName: '', leefBalance: '', stakedBalance: '', canUnstake: false, hoursSinceLastStake: '0' });
  const [stakeAmount, setStakeAmount] = useState('');
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [stakeStatus, setStakeStatus] = useState('');
  const [diagnosticInfo, setDiagnosticInfo] = useState('');
  const [isInStakerlist, setIsInStakerlist] = useState(false);
  const [isStaked, setIsStaked] = useState(false);
  const [formattedLeefBalance, setFormattedLeefBalance] = useState('N/A');
  const [formattedStakedBalance, setFormattedStakedBalance] = useState('N/A');
  const [rewardChartData, setRewardChartData] = useState([]);
  const [roomStakes, setRoomStakes] = useState([]);
  const [availableRooms, setAvailableRooms] = useState([]);
  const [selectedRoom, setSelectedRoom] = useState('');
  const [roomStakeAmount, setRoomStakeAmount] = useState('');
  const [roomUnstakeAmount, setRoomUnstakeAmount] = useState('');
  const [roomStakeStatus, setRoomStakeStatus] = useState('');
  const [leefPrice, setLeefPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [Lleaderboard, setLeaderboard] = useState([]);
  const [activeTab, setActiveTab] = useState('user');
  const [isLeaderboardLoading, setIsLeaderboardLoading] = useState(true);
  const [stakerList, setStakerList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionInfo, setTransactionInfo] = useState(null);
const [activeAdTab, setActiveAdTab] = useState('view');
const [rewardInfo, setRewardInfo] = useState({
  unclaimed_reward: '0.0000 LEEF',
  total_claimed: '0.0000 LEEF',
  last_claim_time: null,
  daily_rate: '0.00657534'
});
  const entriesPerPage = 25;
  const TOKEN_DECIMALS = {
    WAX: 8,
    LEEF: 4
  };
  const [hasNFTBonus, setHasNFTBonus] = useState(false);
const [totalStaked, setTotalStaked] = useState('0.0000');
const [hopperBalance, setHopperBalance] = useState('0.0000');
  const has_required_nft = async (client, username) => {
  try {
    const result = await client.v1.chain.get_table_rows({
      code: "atomicassets",
      scope: username,
      table: "assets",
      index_position: 2,
      key_type: "i64",
      lower_bound: "654718",
      upper_bound: "654718",
      limit: 1
    });
    return result.rows.length > 0;
  } catch (error) {
    console.error('Error checking NFT:', error);
    return false;
  }
};
  const showTransactionInfo = (info) => {
    setTransactionInfo(info);
    setTimeout(() => setTransactionInfo(null), 5000); // Hide after 5 seconds
  };
  const leaderboard = stakerList
    .sort((a, b) => parseFloat(b.fund_staked) - parseFloat(a.fund_staked))
    .map((staker, index) => ({
      rank: index + 1,
      username: staker.username,
      staked: parseFloat(staker.fund_staked).toFixed(4)
    }));

  const fetchStakerList = async (client) => {
    try {
      const result = await client.v1.chain.get_table_rows({
        code: 'leefstakerio',
        scope: 'leefstakerio',
        table: 'stakerlist',
        limit: 1000
      });
      setStakerList(result.rows);
    } catch (error) {
      console.error('Error fetching staker list:', error);
    }
  };
  useEffect(() => {
    const initSessionKit = async () => {
      try {
        const kit = await createSessionKit();
        setSessionKit(kit);
        console.log("SessionKit created:", kit);
        setIsLoading(false);
      } catch (error) {
        console.error('Error initializing SessionKit:', error);
        setDiagnosticInfo(`Error initializing SessionKit: ${error.message}`);
        setIsLoading(false);
      }
    };

    initSessionKit();
    fetchLeefPrice();
    fetchLeaderboard();
     fetchStakerList(new APIClient({ url: "https://wax.greymass.com" }));
    const priceInterval = setInterval(fetchLeefPrice, 60000);
    const leaderboardInterval = setInterval(fetchLeaderboard, 300000);

    return () => {
      clearInterval(priceInterval);
      clearInterval(leaderboardInterval);
    };
  }, []);

  const fetchLeaderboard = async () => {
    setIsLeaderboardLoading(true);
    try {
      const client = new APIClient({ url: "https://wax.greymass.com" });
      const result = await client.v1.chain.get_table_rows({
        json: true,
        code: 'leefstakerio',
        scope: 'leefstakerio',
        table: 'stakerlist',
        limit: 100,
        index_position: 2,
        key_type: 'i64',
        reverse: true
      });

      const leaderboardData = result.rows.map((row, index) => ({
        rank: index + 1,
        username: row.username,
        staked: parseFloat(row.fund_staked).toFixed(4)
      }));

      setLeaderboard(leaderboardData);
    } catch (error) {
      console.error('Error fetching leaderboard:', error);
    } finally {
      setIsLeaderboardLoading(false);
    }
  };
    
  const indexOfLastEntry = currentPage * entriesPerPage;
  const indexOfFirstEntry = indexOfLastEntry - entriesPerPage;
  const currentEntries = leaderboard.slice(indexOfFirstEntry, indexOfLastEntry);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

 const theme = {
    backgroundColor: isDarkMode ? '#121212' : '#f0f7f0',
    textColor: isDarkMode ? '#ffffff' : '#2e7d32',
    cardBackground: isDarkMode ? '#1e1e1e' : 'white',
    buttonBackground: isDarkMode ? '#4CAF50' : '#4CAF50',
    buttonText: 'white',
    highlightBackground: isDarkMode ? '#333333' : '#e8f5e9',
    borderColor: isDarkMode ? '#444444' : '#cccccc',
  };

  const NavBar = () => (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
      backgroundColor: theme.cardBackground,
      borderRadius: '8px',
      padding: '10px',
      boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
    }}>
      {['user', 'room', 'leaderboard'].map((tab) => (
        <button
          key={tab}
          onClick={() => setActiveTab(tab)}
          style={{
            padding: '10px 20px',
            margin: '0 10px',
            backgroundColor: activeTab === tab ? theme.buttonBackground : 'transparent',
            color: activeTab === tab ? theme.buttonText : theme.textColor,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            transition: 'background-color 0.3s',
          }}
        >
          {tab.charAt(0).toUpperCase() + tab.slice(1)}
        </button>
      ))}
    </div>
  );

  const fetchLeefPrice = async () => {
    try {
      const alcor = await axios.get('https://alcor.exchange/api/v2/tokens/leef-leefmaincorp');
      const waxp = await axios.get('https://alcor.exchange/api/v2/tokens/wax-eosio.token');
      const leefbj = await axios.post('https://wax.eosphere.io/v1/chain/get_table_rows', {
        json: true,
        code: 'swap.alcor',
        table: 'pools',
        scope: 'swap.alcor',
        upper_bound: '217',
        lower_bound: '217'
      });

      const leefbj2 = await axios.post('https://wax.eosphere.io/v1/chain/get_table_rows', {
        json: true,
        code: 'swap.box',
        table: 'pairs',
        scope: 'swap.box',
        upper_bound: '140',
        lower_bound: '140'
      });

      const leefbjt = await axios.post('https://wax.eosphere.io/v1/chain/get_table_rows', {
        json: true,
        code: 'swap.taco',
        table: 'pairs',
        scope: 'swap.taco',
        upper_bound: 'LEEWAX',
        lower_bound: 'LEEWAX'
      });

      const lt = leefbjt.data.rows[0].pool1.quantity.split(" ");
      const leeft = lt[0];
      const wt = leefbjt.data.rows[0].pool2.quantity.split(" ");
      const waxt = wt[0];
      const l = leefbj.data.rows[0].tokenB.quantity.split(" ");
      const leef = l[0];
      const w = leefbj.data.rows[0].tokenA.quantity.split(" ");
      const wax = w[0];
      const poola = leefbj2.data.rows[0].reserve0.split(" ");
      const poolb = leefbj2.data.rows[0].reserve1.split(" ");
      const alp = alcor.data.system_price;

      const leefa = Number(Number(poolb[0]) + Number(leeft)).toFixed(4);
      const waxa = Number(Number(poola[0]) + Number(waxt)).toFixed(8);
      const ppp = Number((Number(waxa / leefa) + Number(alp)) / 2).toFixed(8);
      const usp = ppp * waxp.data.usd_price;

      const priceData = {
        leef: Number(Number(leefa) + Number(leef)).toFixed(4),
        wax: Number(Number(waxa) + Number(wax)).toFixed(8),
        price: Number(ppp).toFixed(8),
        usdp: Number(usp).toFixed(10)
      };

      setLeefPrice(priceData);
      console.log('LEEF price updated', priceData);
    } catch (error) {
      console.error('Error updating LEEF price:', error);
    }
  };



  const getTokenBalance = async (client, accountName, code, symbol) => {
    try {
      console.log(`Fetching ${symbol} balance for ${accountName} from contract ${code}`);
      const result = await client.v1.chain.get_currency_balance(code, accountName, symbol);
      console.log(`Raw ${symbol} balance result:`, result);
      return result.length > 0 ? result[0] : `0.0000 ${symbol}`;
    } catch (error) {
      console.error(`Error fetching ${symbol} balance:`, error);
      return `Error: ${error.message}`;
    }
  };

  const checkStakerlist = async (client, accountName) => {
    try {
      console.log(`Checking stakerlist for ${accountName}`);
      const result = await client.v1.chain.get_table_rows({
        code: 'leefstakerio',
        scope: 'leefstakerio',
        table: 'stakerlist',
        lower_bound: accountName,
        upper_bound: accountName,
        limit: 1
      });
      console.log(`Stakerlist check result:`, result);

      if (result.rows.length > 0) {
        const stakeInfo = result.rows[0];
        console.log('Stake info:', stakeInfo);

        setIsInStakerlist(true);
        setIsStaked(stakeInfo.isstaked === 1);

        return {
          isInStakerlist: true,
          isStaked: stakeInfo.isstaked === 1,
          stakedAmount: stakeInfo.fund_staked,
          fullStakeInfo: stakeInfo
        };
      } else {
        setIsInStakerlist(false);
        setIsStaked(false);

        return {
          isInStakerlist: false,
          isStaked: false,
          stakedAmount: '0.0000 LEEF',
          fullStakeInfo: null
        };
      }
    } catch (error) {
      console.error(`Error checking stakerlist:`, error);
      setIsInStakerlist(false);
      setIsStaked(false);

      return {
        isInStakerlist: false,
        isStaked: false,
        stakedAmount: '0.0000 LEEF',
        error: error.message
      };
    }
  };

  const checkStakeTime = async (client, accountName) => {
    try {
      console.log(`Checking stake time for ${accountName}`);
      const result = await client.v1.chain.get_table_rows({
        code: 'leefstakerio',
        scope: 'leefstakerio',
        table: 'timelist',
        lower_bound: accountName,
        upper_bound: accountName,
        limit: 1
      });
      console.log(`Stake time check result:`, result);

      if (result.rows.length > 0) {
        const stakeTimeInfo = result.rows[0];
        if (stakeTimeInfo.time) {
          const lastStakeTime = new Date(stakeTimeInfo.time * 1000);
          const currentTime = new Date();
          const timeDifference = currentTime - lastStakeTime;
          const hoursPassed = timeDifference / (1000 * 60 * 60);

          console.log(`Hours passed since last stake: ${hoursPassed}`);

          return {
            canUnstake: hoursPassed >= 24,
            hoursPassed: hoursPassed,
            lastStakeTime: lastStakeTime.toISOString(),
            username: stakeTimeInfo.username
          };
        }
      }
      
      console.log("No valid stake time information found");
      return {
        canUnstake: true,
        hoursPassed: 24,
        lastStakeTime: null,
        username: accountName,
        error: 'No valid stake time found'
      };
    } catch (error) {
      console.error(`Error checking stake time:`, error);
      return {
        canUnstake: true,
        hoursPassed: 24,
        lastStakeTime: null,
        username: accountName,
        error: error.message
      };
    }
  };

const handleClaim = async () => {
  if (!session || !accountInfo.accountName) {
    setStakeStatus('Please login first');
    return;
  }

  try {
    setDiagnosticInfo("Attempting to claim rewards...");
    const action = {
      account: 'leefrewarder',
      name: 'claim',
      authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
      data: {
        user: accountInfo.accountName,
      },
    };

    console.log("Claim action:", action);
    const result = await session.transact({ action });
    console.log('Claim transaction result:', result);
    setStakeStatus('Claim successful!');
    setDiagnosticInfo("Claim successful. Updating balances...");
    
    showTransactionInfo({
      type: 'Claim Rewards',
      amount: 'LEEF',
      transactionId: result.response.transaction_id
    });

    await updateBalances(session);
  } catch (error) {
    console.error('Claim error:', error);
    setStakeStatus(`Claim failed: ${error.message}`);
    setDiagnosticInfo(`Claim failed: ${error.message}`);
  }
};
  const fetchRewardData = async (client, accountName) => {
    try {
      console.log(`Fetching reward data for ${accountName}`);

    } catch (error) {
      console.error('Error fetching reward data:', error);
      return null;
    }
  };

  const processRewardData = (rewardData) => {
    if (!rewardData) return [];

    const currentTime = Math.floor(Date.now() / 1000);
    const weekInSeconds = 7 * 24 * 60 * 60;
    const data = [];

    for (let i = 0; i < 10; i++) {
      const weekStart = currentTime - (i * weekInSeconds);
      const weekReward = parseFloat(rewardData.week_rewards[i]) / 10000; // Assuming 4 decimal places
      data.unshift({
        week: `Week ${10 - i}`,
        reward: weekReward,
      });
    }

    return data;
  };

  const formatBalance = (balance) => {
    if (typeof balance === 'string') {
      const [amount, symbol] = balance.split(' ');
      if (symbol === 'LEEF') {
        // Format to 4 decimal places
        return `${parseFloat(amount).toFixed(4)} LEEF`;
      }
    }
    if (typeof balance === 'number') {
      return `${balance.toFixed(4)} LEEF`;
    }
    console.log('Unexpected balance format:', balance);
    return 'N/A';
  };


  const fetchAvailableRooms = async (client) => {
    try {
      const result = await client.v1.chain.get_table_rows({
        code: 'leefstakerio',
        scope: 'leefstakerio',
        table: 'stakerlist',
        limit: 1000
      });
      const rooms = result.rows.map(row => row.username);
      setAvailableRooms(rooms);
    } catch (error) {
      console.error('Error fetching available rooms:', error);
    }
  };

 const handleRoomStake = async () => {
    if (!session || !accountInfo.accountName || !selectedRoom) {
      setRoomStakeStatus('Please login and select a room first');
      return;
    }

    try {
      setDiagnosticInfo("Attempting room stake...");
      const amount = parseFloat(roomStakeAmount).toFixed(4);
      const action = {
        account: 'leefstakerio',
        name: 'roomstake',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          username: accountInfo.accountName,
          room: selectedRoom,
          quantity: `${amount} LEEF`,
        },
      };

      console.log("Room stake action:", action);
      const result = await session.transact({ action });
      console.log('Room stake transaction result:', result);
      setRoomStakeStatus('Room staking successful!');
      setDiagnosticInfo("Room staking successful. Updating balances...");
      
      showTransactionInfo({
        type: 'Room Stake',
        amount: `${amount} LEEF`,
        room: selectedRoom,
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Room staking error:', error);
      setRoomStakeStatus(`Room staking failed: ${error.message}`);
      setDiagnosticInfo(`Room staking failed: ${error.message}`);
    }
  };

  const handleRoomUnstake = async () => {
    if (!session || !accountInfo.accountName || !selectedRoom) {
      setRoomStakeStatus('Please login and select a room first');
      return;
    }

    try {
      setDiagnosticInfo("Attempting room unstake...");
      const amount = parseFloat(roomUnstakeAmount).toFixed(4);
      const action = {
        account: 'leefstakerio',
        name: 'roomunstake',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          username: accountInfo.accountName,
          room: selectedRoom,
          quantity: `${amount} LEEF`,
        },
      };

      console.log("Room unstake action:", action);
      const result = await session.transact({ action });
      console.log('Room unstake transaction result:', result);
      setRoomStakeStatus('Room unstaking successful!');
      setDiagnosticInfo("Room unstaking successful. Updating balances...");
      
      showTransactionInfo({
        type: 'Room Unstake',
        amount: `${amount} LEEF`,
        room: selectedRoom,
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Room unstaking error:', error);
      setRoomStakeStatus(`Room unstaking failed: ${error.message}`);
      setDiagnosticInfo(`Room unstaking failed: ${error.message}`);
    }
  };
const fetchRoomStakes = async (client, accountName) => {
    try {
      console.log("Fetching room stakes");
      const result = await client.v1.chain.get_table_by_scope({
        code: 'leefstakerio',
        table: 'roomlist',
        limit: 1000
      });

      console.log("Room scopes:", result);

      const roomStakes = [];
      for (const scope of result.rows) {
        const stakeResult = await client.v1.chain.get_table_rows({
          code: 'leefstakerio',
          scope: scope.scope,
          table: 'roomlist',
          lower_bound: accountName,
          upper_bound: accountName,
          limit: 1
        });

        if (stakeResult.rows.length > 0) {
          roomStakes.push({
            room: scope.scope,
            amount: stakeResult.rows[0].fund_staked
          });
        }
      }

      console.log("User room stakes:", roomStakes);
      return roomStakes;
    } catch (error) {
      console.error("Error fetching room stakes:", error);
      return [];
    }
  };


  const updateBalances = async (currentSession) => {
    try {
      const accountName = currentSession.actor.toString();
      console.log("Updating balances for account:", accountName);

      const client = new APIClient({ url: "https://wax.greymass.com" });
 
      const fetchedRoomStakes = await fetchRoomStakes(client, accountName);
      setRoomStakes(fetchedRoomStakes);

      // Fetch LEEF balance
      const leefBalance = await getTokenBalance(client, accountName, "leefmaincorp", "LEEF");
      console.log("Raw LEEF balance:", leefBalance);

      // Check stakerlist
      const stakeInfo = await checkStakerlist(client, accountName);
      console.log("Stake info:", stakeInfo);

      // Check stake time
      const stakeTimeInfo = await checkStakeTime(client, accountName);
      console.log("Stake time info:", stakeTimeInfo);

      // Fetch and process reward data
      const rewardData = await fetchRewardData(client, accountName);
      const processedRewardData = processRewardData(rewardData);
      setRewardChartData(processedRewardData);

      const formattedLeef = formatBalance(leefBalance.toString());
      const formattedStaked = formatBalance(stakeInfo.stakedAmount);

      console.log("Formatted LEEF balance:", formattedLeef);
      console.log("Formatted staked balance:", formattedStaked);

      setFormattedLeefBalance(formattedLeef);
      setFormattedStakedBalance(formattedStaked);

      
      
        // Inside updateBalances function
        // Inside updateBalances function after getting stakeInfo
const hasNFT = await has_required_nft(client, accountName);
setHasNFTBonus(hasNFT);

// Get total staked amount from all users
const allStakersResult = await client.v1.chain.get_table_rows({
  code: 'leefstakerio',
  scope: 'leefstakerio',
  table: 'stakerlist',
  limit: 1000
});

let totalStakedAmount = 0;
for (const staker of allStakersResult.rows) {
  const hasNFTBonus = await has_required_nft(client, staker.username);
  const stakedAmount = parseFloat(staker.fund_staked);
  totalStakedAmount += hasNFTBonus ? stakedAmount * 1.1 : stakedAmount;
}
setTotalStaked(totalStakedAmount.toFixed(4));

// Get hopper balance
const hopperBalanceResult = await client.v1.chain.get_currency_balance(
  'leefmaincorp',
  'leefrewarder',
  'LEEF'
);
setHopperBalance(hopperBalanceResult[0] || '0.0000 LEEF');
        
await fetchRewardInfo(client, accountName);
      await fetchAvailableRooms(client);
      const transferHistory = await fetchTransferHistory(client, accountName);
      const processedTransferData = processTransferHistory(transferHistory);
      console.log("Processed transfer data:", processedTransferData);
      setRewardChartData(processedTransferData);
    
      setAccountInfo({
        accountName: accountName,
        leefBalance: formattedLeef,
        stakedBalance: formattedStaked,
        canUnstake: stakeTimeInfo.canUnstake,
        hoursSinceLastStake: stakeTimeInfo.hoursPassed.toFixed(2)
      });

      setDiagnosticInfo(
        `Account: ${accountName}\n` +
        `LEEF Balance: ${formattedLeef}\n` +
        `In Stakerlist: ${stakeInfo.isInStakerlist ? 'Yes' : 'No'}\n` +
        `Is Staked: ${stakeInfo.isStaked ? 'Yes' : 'No'}\n` +
        `Current Stake: ${formattedStaked}\n` +
        `Can Unstake: ${stakeTimeInfo.canUnstake ? 'Yes' : 'No'}\n` +
        `Hours Since Last Stake: ${stakeTimeInfo.hoursPassed.toFixed(2)}\n` +
        `Last Stake Time: ${stakeTimeInfo.lastStakeTime || 'Unknown'}\n` +
        `Stake Time Username: ${stakeTimeInfo.username}\n` +
        `Stake Time Error: ${stakeTimeInfo.error || 'None'}\n\n` +
        `Raw LEEF Balance: ${JSON.stringify(leefBalance)}\n` +
        `Raw Staked Amount: ${JSON.stringify(stakeInfo.stakedAmount)}\n\n` +
        `Full Stake Info:\n${JSON.stringify(stakeInfo.fullStakeInfo, null, 2)}`
      );
    } catch (error) {
      console.error('Error updating balances:', error);
      setDiagnosticInfo(`Error updating balances: ${error.message}`);
    }
  };

  const handleLogin = async () => {
    if (!sessionKit) {
      setDiagnosticInfo("SessionKit not initialized. Please refresh the page.");
      return;
    }

    try {
      setIsLoading(true);
      console.log("Attempting login...");
      setDiagnosticInfo("Attempting login...");
      const result = await sessionKit.login();
      if (result.session) {
        console.log("Login successful:", result.session);
        setSession(result.session);
        setDiagnosticInfo("Login successful. Updating balances...");
        await updateBalances(result.session);
      } else {
        console.log("Login failed: No session returned");
        setDiagnosticInfo("Login failed: No session returned");
      }
    } catch (error) {
      console.error('Login error:', error);
      setDiagnosticInfo(`Login error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };


  const handleStake = async () => {
    if (!session || !accountInfo.accountName) {
      setStakeStatus('Please login first');
      return;
    }

    try {
      setDiagnosticInfo("Attempting stake...");
      const amount = parseFloat(stakeAmount).toFixed(4);
      const action = {
        account: 'leefmaincorp',
        name: 'transfer',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          from: accountInfo.accountName,
          to: 'leefstakerio',
          quantity: `${amount} LEEF`,
          memo: 'increment',
        },
      };

      console.log("Stake action:", action);
      const result = await session.transact({ action });
      console.log('Stake transaction result:', result);
      setStakeStatus('Staking successful!');
      setDiagnosticInfo("Staking successful. Updating balances...");
      
      showTransactionInfo({
        type: 'Stake',
        amount: `${amount} LEEF`,
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Staking error:', error);
      setStakeStatus(`Staking failed: ${error.message}`);
      setDiagnosticInfo(`Staking failed: ${error.message}`);
    }
  };

  const handleUnstake = async () => {
    if (!session || !accountInfo.accountName) {
      setStakeStatus('Please login first');
      return;
    }

    if (!accountInfo.canUnstake) {
      setStakeStatus('You must wait 24 hours between unstaking actions');
      return;
    }

    try {
      setDiagnosticInfo("Attempting unstake...");
      const amount = parseFloat(unstakeAmount).toFixed(4);
      const action = {
        account: 'leefstakerio',
        name: 'unstake',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          username: accountInfo.accountName,
          quantity: `${amount} LEEF`,
        },
      };

      console.log("Unstake action:", action);
      const result = await session.transact({ action });
      console.log('Unstake transaction result:', result);
      setStakeStatus('Unstaking successful!');
      setDiagnosticInfo("Unstaking successful. Updating balances...");
      
      showTransactionInfo({
        type: 'Unstake',
        amount: `${amount} LEEF`,
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Unstaking error:', error);
      setStakeStatus(`Unstaking failed: ${error.message}`);
      setDiagnosticInfo(`Unstaking failed: ${error.message}`);
    }
  };

const handleRegisterStaker = async () => {
    if (!session || !accountInfo.accountName) {
      setStakeStatus('Please login first');
      return;
    }

    try {
      setDiagnosticInfo("Attempting to register as staker...");
      const action = {
        account: 'leefstakerio',
        name: 'regstaker',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          username: accountInfo.accountName,
        },
      };

      console.log("Register staker action:", action);
      const result = await session.transact({ action });
      console.log('Register staker transaction result:', result);
      setStakeStatus('Successfully registered as staker!');
      setDiagnosticInfo("Successfully registered as staker. Updating balances...");
      
      showTransactionInfo({
        type: 'Register Staker',
        amount: 'N/A',
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Register staker error:', error);
      setStakeStatus(`Failed to register as staker: ${error.message}`);
      setDiagnosticInfo(`Failed to register as staker: ${error.message}`);
    }
  };

  const handleInitialStake = async () => {
    if (!session || !accountInfo.accountName) {
      setStakeStatus('Please login first');
      return;
    }

    try {
      setDiagnosticInfo("Attempting initial stake of 5000 LEEF...");
      const action = {
        account: 'leefmaincorp',
        name: 'transfer',
        authorization: [{ actor: accountInfo.accountName, permission: 'active' }],
        data: {
          from: accountInfo.accountName,
          to: 'leefstakerio',
          quantity: '5000.0000 LEEF',
          memo: 'start',
        },
      };

      console.log("Initial stake action:", action);
      const result = await session.transact({ action });
      console.log('Initial stake transaction result:', result);
      setStakeStatus('Initial staking successful!');
      setDiagnosticInfo("Initial staking successful. Updating balances...");
      
      showTransactionInfo({
        type: 'Initial Stake',
        amount: '5000.0000 LEEF',
        transactionId: result.response.transaction_id
      });

      await updateBalances(session);
    } catch (error) {
      console.error('Initial staking error:', error);
      setStakeStatus(`Initial staking failed: ${error.message}`);
      setDiagnosticInfo(`Initial staking failed: ${error.message}`);
    }
  };

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const fetchTransferHistory = async (client, accountName) => {
  console.log(`Starting fetchTransferHistory for ${accountName}`);
  let transfers = [];
  const batchSize = 100;
  const maxTransfers = 10000;
  const maxRetries = 3;

  const fetchBatch = async (account, startPos, retryCount = 0) => {
    try {
      console.log(`Fetching batch for ${account} starting at position ${startPos}`);
      const result = await client.v1.history.get_actions(account, startPos, -batchSize);
      console.log(`Received ${result.actions.length} actions for ${account}`);
      return result.actions.filter(action => 
        action.action_trace.act.name === 'transfer' && 
        ((account === 'leefrewarder' && action.action_trace.act.data.to === accountName) ||
         (account === accountName && action.action_trace.act.data.from === 'leefrewarder')) &&
        action.action_trace.act.data.quantity.includes('LEEF')
      ).map(action => ({
        from: action.action_trace.act.data.from,
        to: action.action_trace.act.data.to,
        amount: parseFloat(action.action_trace.act.data.quantity.split(' ')[0]),
        timestamp: new Date(action.block_time + 'Z').getTime(),
        memo: action.action_trace.act.data.memo
      }));
    } catch (error) {
      console.error(`Error fetching batch for ${account} at position ${startPos}:`, error);
      if (retryCount < maxRetries) {
        console.log(`Retrying (${retryCount + 1}/${maxRetries}) for ${account} at position ${startPos}`);
        await sleep(Math.pow(2, retryCount) * 1000);
        return fetchBatch(account, startPos, retryCount + 1);
      }
      return [];
    }
  };

  const fetchAllTransfers = async (account) => {
    let pos = -1;
    while (transfers.length < maxTransfers) {
      const newTransfers = await fetchBatch(account, pos);
      if (newTransfers.length === 0) break;
      transfers = [...transfers, ...newTransfers];
      console.log(`Fetched ${transfers.length} transfers so far for ${account}`);
      pos = newTransfers[newTransfers.length - 1].account_action_seq - 1;
      if (newTransfers.length < batchSize) break;
      await sleep(100);
    }
  };

  try {
    // Try fetching from leefrewarder first
    await fetchAllTransfers('leefrewarder');
    
    // If we didn't get any transfers, try fetching from the user's account
    if (transfers.length === 0) {
      console.log(`No transfers found from leefrewarder. Trying user account ${accountName}`);
      await fetchAllTransfers(accountName);
    }

    console.log(`Total transfers fetched: ${transfers.length}`);
    return transfers;
  } catch (error) {
    console.error('Error in fetchTransferHistory:', error);
    return [];
  }
};


const processTransferHistory = (transfers) => {
  const now = new Date();
  const oneWeekAgo = now.getTime() - 7 * 24 * 60 * 60 * 1000;

  const recentTransfers = transfers
    .filter(transfer => transfer.timestamp >= oneWeekAgo)
    .sort((a, b) => a.timestamp - b.timestamp);

  let total = 0;
  return recentTransfers.map(transfer => {
    total += transfer.amount;
    return {
      timestamp: transfer.timestamp,
      amount: transfer.amount,
      total: total
    };
  });
};

const fetchRewardInfo = async (client, accountName) => {
  try {
    // Get the hopper balance directly from leefrewarder contract
    const leefBalanceResponse = await client.v1.chain.get_table_rows({
      code: 'leefmaincorp',
      scope: 'leefrewarder',
      table: 'accounts',
      limit: 1
    });

    // Extract and parse hopper balance
    const hopperBalance = leefBalanceResponse.rows.length > 0 
      ? parseFloat(leefBalanceResponse.rows[0].balance.split(' ')[0])
      : 0;

    // Get unclaimed rewards
    const rewardsResult = await client.v1.chain.get_table_rows({
      code: 'leefrewarder',
      scope: 'leefrewarder',
      table: 'rewards',
      lower_bound: accountName,
      upper_bound: accountName,
      limit: 1
    });

    // Get total unclaimed rewards
    const allRewardsResult = await client.v1.chain.get_table_rows({
      code: 'leefrewarder',
      scope: 'leefrewarder',
      table: 'rewards',
      limit: 1000
    });

    // Parse unclaimed rewards
    const userUnclaimedReward = rewardsResult.rows.length > 0 
      ? rewardsResult.rows[0].unclaimed_reward 
      : '0.0000 LEEF';

    let totalUnclaimed = 0;
    allRewardsResult.rows.forEach(row => {
      const amount = parseFloat(row.unclaimed_reward.split(' ')[0]);
      totalUnclaimed += isNaN(amount) ? 0 : amount;
    });

    // Calculate available balance
    const availableBalance = Math.max(0, hopperBalance - totalUnclaimed);

    // Parse stake amounts
    const userStake = Number(parseFloat(formattedStakedBalance?.split(' ')[0] || 0));
    const totalStakeAmount = parseFloat(totalStaked || 0);

    // Calculate stake ratio
    const stakeRatio = totalStakeAmount > 0 ? 
      (hasNFTBonus ? (userStake * 1.1) : userStake) / totalStakeAmount : 
      0;

    // Calculate rewards
    const dailyRate = 0.00657534;
    const dailyReward = availableBalance * stakeRatio * dailyRate;

    console.log({
      formattedStakedBalance,
      hopperBalance,
      totalUnclaimed,
      availableBalance,
      userStake,
      totalStakeAmount,
      stakeRatio,
      dailyReward
    });

    setRewardInfo({
      unclaimed_reward: userUnclaimedReward,
      available_balance: `${availableBalance.toFixed(4)} LEEF`,
      daily_rate: dailyRate.toString(),
      projected_daily: `${dailyReward.toFixed(4)} LEEF`,
      hasNFTBonus: hasNFTBonus
    });

  } catch (error) {
    console.error('Error fetching reward info:', error);
    setRewardInfo(prev => ({
      ...prev,
      available_balance: '0.0000 LEEF',
      projected_daily: '0.0000 LEEF'
    }));
  }
};

 const RewardChart = ({ data }) => {
    console.log("Rendering RewardChart with data:", data);
    if (data.length === 0) {
      return <p>No transfer data available.</p>;
    }

    return (
      <ResponsiveContainer width="100%" height={300}>
        <ScatterChart
          margin={{
            top: 20,
            right: 20,
            bottom: 20,
            left: 20,
          }}
        >
          <XAxis 
            type="number" 
            dataKey="timestamp" 
            domain={['dataMin', 'dataMax']}
            tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
            name="Time"
            stroke={theme.textColor}
          />
          <YAxis type="number" dataKey="amount" name="Amount" unit=" LEEF" stroke={theme.textColor} />
          <Tooltip content={<CustomTooltip />} />
          <Scatter name="Payout" data={data} fill={theme.buttonBackground} />
        </ScatterChart>
      </ResponsiveContainer>
    );
  };
  
  
    const CustomTooltip = ({ active, payload, label, theme }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      return (
        <div style={{ 
          backgroundColor: theme.cardBackground, 
          padding: '10px', 
          border: `1px solid ${theme.borderColor}`,
          borderRadius: '5px',
        }}>
          <p style={{ color: theme.textColor, margin: '0 0 5px 0' }}>Time: {new Date(data.timestamp).toLocaleString()}</p>
          <p style={{ color: theme.textColor, margin: '0 0 5px 0' }}>Amount: {data.amount.toFixed(4)} LEEF</p>
          <p style={{ color: theme.textColor, margin: '0' }}>Total: {data.total.toFixed(4)} LEEF</p>
        </div>
      );
    }
    return null;
  };
  return (
   <div style={{
      fontFamily: 'Arial, sans-serif',
      maxWidth: '100%',
      margin: '0 auto',
      padding: '10px',
      backgroundColor: theme.backgroundColor,
      color: theme.textColor,
      borderRadius: '10px',
      boxShadow: '0 0 20px rgba(0, 0, 0, 0.1)',
      boxSizing: 'border-box',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: 'all 0.3s ease',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
        flexWrap: 'wrap',
      }}>
        <h1 style={{
          color: theme.textColor,
          fontSize: 'calc(18px + 2vmin)',
          margin: '10px 0',
          textAlign: 'center',
          width: '100%',
        }}>LEEF Staking Dashboard</h1>
        <button
          onClick={toggleDarkMode}
          style={{
            padding: '10px',
            backgroundColor: theme.buttonBackground,
            color: theme.buttonText,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '14px',
            margin: '10px auto',
          }}
        >
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </div>

      <NavBar />
      
      {/* LEEF Price Display */}
      <div style={{
        width: '100%',
        backgroundColor: theme.cardBackground,
        padding: '15px',
        borderRadius: '8px',
        marginBottom: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        textAlign: 'center'
      }}>
        <h3 style={{ 
          color: theme.textColor, 
          marginBottom: '15px',
          fontSize: '16px'
        }}>Current LEEF Price</h3>
        {leefPrice ? (
          <div>
            <p><strong>LEEF/WAX:</strong> {leefPrice.price}</p>
            <p><strong>LEEF/USD:</strong> ${leefPrice.usdp}</p>
          </div>
        ) : (
          <p>Loading LEEF price...</p>
        )}
      </div>

      {activeTab === 'user' && (
        <div style={{
          width: '100%',
          backgroundColor: theme.cardBackground,
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '20px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}>
        {isLoading ? (
          <p style={{ 
            color: theme.textColor, 
            fontSize: '18px',
            textAlign: 'center'
          }}>Loading user profile...</p>
        ) : !session ? (
          <div style={{
            textAlign: 'center',
          }}>
            <h2 style={{ 
              color: theme.textColor, 
              marginBottom: '15px',
              fontSize: 'calc(16px + 1vmin)',
            }}>Welcome to LEEF Staking</h2>
            <p style={{ 
              marginBottom: '20px', 
              color: theme.textColor,
              fontSize: 'calc(14px + 0.5vmin)',
            }}>Please login to manage your LEEF tokens and stakes.</p>
            <button 
              onClick={handleLogin} 
              disabled={!sessionKit}
              style={{
                padding: '12px 24px',
                fontSize: '16px',
                backgroundColor: theme.buttonBackground,
                color: theme.buttonText,
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
            >
              Login to Leefstaker
            </button>
          </div>
        ) : (
          <div>
            <h2 style={{ 
              color: theme.textColor, 
              marginBottom: '15px',
              fontSize: '20px',
              textAlign: 'center',
            }}>Welcome, {session.actor.toString()}</h2>
             <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: theme.highlightBackground,
        padding: '15px',
        borderRadius: '5px',
        marginBottom: '20px',
      }}>
        <div style={{ marginBottom: '10px', textAlign: 'center' }}>
          <p style={{ fontWeight: 'bold', color: theme.textColor, marginBottom: '5px', fontSize: '14px' }}>LEEF Balance:</p>
          <p style={{ 
            fontSize: '18px', 
            color: theme.textColor,
            wordBreak: 'break-all',
          }}>{formattedLeefBalance}</p>
        </div>
        <div style={{ textAlign: 'center' }}>
          <p style={{ fontWeight: 'bold', color: theme.textColor, marginBottom: '5px', fontSize: '14px' }}>Current Stake:</p>
          <p style={{ 
            fontSize: '18px', 
            color: theme.textColor,
            wordBreak: 'break-all',
          }}>{formattedStakedBalance}</p>
        </div>
      </div>

            {isInStakerlist ? (
              isStaked ? (
                <div>
                  <h3 style={{ 
                    color: theme.textColor, 
                    marginBottom: '15px',
                    fontSize: '18px',
                    textAlign: 'center',
                  }}>Manage Your Stakes</h3>
                  <div style={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '20px',
                  }}>
                    <div style={{ width: '100%' }}>
                      <h4 style={{ 
                        color: theme.textColor, 
                        marginBottom: '10px',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}>Stake LEEF Tokens</h4>
                      <input
                        type="number"
                        placeholder="Amount to stake"
                        value={stakeAmount}
                        onChange={(e) => setStakeAmount(e.target.value)}
                        style={{
                          width: '100%',
                          padding: '10px',
                          marginBottom: '10px',
                          borderRadius: '5px',
                          border: `1px solid ${theme.borderColor}`,
                          fontSize: '14px',
                          textAlign: 'center',
                          backgroundColor: theme.cardBackground,
                          color: theme.textColor,
                        }}
                      />
                      <button onClick={handleStake} style={{
                        width: '100%',
                        padding: '10px',
                        backgroundColor: theme.buttonBackground,
                        color: theme.buttonText,
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '14px',
                      }}>Stake</button>
                    </div>
                    <div style={{ width: '100%' }}>
                      <h4 style={{ 
                        color: theme.textColor, 
                        marginBottom: '10px',
                        fontSize: '16px',
                        textAlign: 'center',
                      }}>Unstake LEEF Tokens</h4>
                      <input
                        type="number"
                        placeholder="Amount to unstake"
                        value={unstakeAmount}
                        onChange={(e) => setUnstakeAmount(e.target.value)}
                        style={{
                          width: '100%',
                          padding: '10px',
                          marginBottom: '10px',
                          borderRadius: '5px',
                          border: `1px solid ${theme.borderColor}`,
                          fontSize: '14px',
                          textAlign: 'center',
                          backgroundColor: theme.cardBackground,
                          color: theme.textColor,
                        }}
                      />
                      <button 
                        onClick={handleUnstake} 
                        disabled={!accountInfo.canUnstake}
                        style={{
                          width: '100%',
                          padding: '10px',
                          backgroundColor: accountInfo.canUnstake ? theme.buttonBackground : theme.borderColor,
                          color: theme.buttonText,
                          border: 'none',
                          borderRadius: '5px',
                          cursor: accountInfo.canUnstake ? 'pointer' : 'not-allowed',
                          fontSize: '14px',
                        }}
                      >
                        {accountInfo.canUnstake ? 'Unstake' : `Unstake (${(24 - parseFloat(accountInfo.hoursSinceLastStake)).toFixed(2)} hours left)`}
                      </button>
                    </div>
                  </div>
                  {stakeStatus && <p style={{ color: theme.textColor, marginTop: '15px', textAlign: 'center', fontSize: '14px' }}>{stakeStatus}</p>}
                </div>
              ) : (
                <div style={{
                  textAlign: 'center',
                }}>
                  <p style={{ 
                    color: theme.textColor, 
                    marginBottom: '15px',
                    fontSize: '14px',
                  }}>You are registered but not staked. Please stake 1 LEEF to start.</p>
                  <button onClick={handleInitialStake} style={{
                    padding: '10px 20px',
                    fontSize: '14px',
                    backgroundColor: theme.buttonBackground,
                    color: theme.buttonText,
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}>Stake 5000 LEEF to Start</button>
                </div>
              )
            ) : (
              <div style={{
                textAlign: 'center',
              }}>
                <p style={{ 
                  color: theme.textColor, 
                  marginBottom: '15px',
                  fontSize: '14px',
                }}>You are not in the stakerlist. Please register as a staker to continue.</p>
                <button onClick={handleRegisterStaker} style={{
                  padding: '10px 20px',
                  fontSize: '14px',
                  backgroundColor: theme.buttonBackground,
                  color: theme.buttonText,
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}>Register as Staker</button>
              </div>
            )}
            {stakeStatus && <p style={{ color: theme.textColor, marginTop: '15px', textAlign: 'center', fontSize: '14px' }}>{stakeStatus}</p>}
          </div>
        )}
      </div>
    )}

       {activeTab === 'room' && (
        <div style={{
          width: '100%',
          backgroundColor: theme.cardBackground,
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '20px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}>
        <h3 style={{ 
          color: theme.textColor, 
          marginBottom: '15px',
          fontSize: '18px',
          textAlign: 'center'
        }}>Room Stakes</h3>
        {Array.isArray(roomStakes) && roomStakes.length > 0 ? (
          <ul style={{ listStyle: 'none', padding: 0 }}>
            {roomStakes.map((stake, index) => (
              <li key={index} style={{ 
                marginBottom: '10px', 
                padding: '10px', 
                backgroundColor: theme.highlightBackground, 
                borderRadius: '5px',
                color: theme.textColor,
              }}>
                <p style={{ margin: 0 }}>
                  <strong>Room:</strong> {stake.room ? String(stake.room) : 'N/A'}
                </p>
                <p style={{ margin: 0 }}>
                  <strong>Staked:</strong> {formatBalance(stake.amount)}
                </p>
              </li>
            ))}
          </ul>
        ) : (
          <p style={{ textAlign: 'center', color: theme.textColor }}>No room stakes found.</p>
        )}
        {isInStakerlist && isStaked && (
          <>
            <h4 style={{ 
              color: theme.textColor, 
              marginBottom: '10px',
              fontSize: '16px',
              textAlign: 'center',
            }}>Stake in Room</h4>
            <select
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: `1px solid ${theme.borderColor}`,
                fontSize: '14px',
                backgroundColor: theme.cardBackground,
                color: theme.textColor,
              }}
            >
              <option value="">Select a room</option>
              {availableRooms.map((room) => (
                <option key={room} value={room}>{String(room)}</option>
              ))}
            </select>
            <input
              type="number"
              placeholder="Amount to stake in room"
              value={roomStakeAmount}
              onChange={(e) => setRoomStakeAmount(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: `1px solid ${theme.borderColor}`,
                fontSize: '14px',
                backgroundColor: theme.cardBackground,
                color: theme.textColor,
              }}
            />
            <button onClick={handleRoomStake} style={{
              width: '100%',
              padding: '10px',
              backgroundColor: theme.buttonBackground,
              color: theme.buttonText,
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '14px',
              marginBottom: '20px',
            }}>Stake in Room</button>
            <h4 style={{ 
              color: theme.textColor, 
              marginBottom: '10px',
              fontSize: '16px',
              textAlign: 'center',
            }}>Unstake from Room</h4>
            <select
              value={selectedRoom}
              onChange={(e) => setSelectedRoom(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: `1px solid ${theme.borderColor}`,
                fontSize: '14px',
                backgroundColor: theme.cardBackground,
                color: theme.textColor,
              }}
            >
              <option value="">Select a room</option>
              {roomStakes.map((stake) => (
                <option key={stake.room} value={String(stake.room)}>{String(stake.room)}</option>
              ))}
            </select>
            <input
              type="number"
              placeholder="Amount to unstake from room"
              value={roomUnstakeAmount}
              onChange={(e) => setRoomUnstakeAmount(e.target.value)}
              style={{
                width: '100%',
                padding: '10px',
                marginBottom: '10px',
                borderRadius: '5px',
                border: `1px solid ${theme.borderColor}`,
                fontSize: '14px',
                backgroundColor: theme.cardBackground,
                color: theme.textColor,
              }}
            />
            <button onClick={handleRoomUnstake} style={{
              width: '100%',
              padding: '10px',
              backgroundColor: theme.buttonBackground,
              color: theme.buttonText,
              border: 'none',
              borderRadius: '5px',
              cursor: 'pointer',
              fontSize: '14px',
            }}>Unstake from Room</button>
          </>
        )}
        {roomStakeStatus && <p style={{ color: theme.textColor, marginTop: '15px', textAlign: 'center', fontSize: '14px' }}>{roomStakeStatus}</p>}
      </div>
    )}

    {activeTab === 'leaderboard' && (
        <div style={{
          width: '100%',
          backgroundColor: theme.cardBackground,
          padding: '15px',
          borderRadius: '8px',
          marginBottom: '20px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
          overflowX: 'auto',
        }}>
          <h3 style={{ 
            color: theme.textColor, 
            marginBottom: '15px',
            fontSize: '16px',
            textAlign: 'center'
          }}>Staking Leaderboard</h3>
          {leaderboard.length > 0 ? (
            <>
              <table style={{
                width: '100%',
                borderCollapse: 'collapse',
                fontSize: '14px',
              }}>
                <thead>
                  <tr>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>Rank</th>
                    <th style={{ padding: '8px', textAlign: 'left', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>Username</th>
                    <th style={{ padding: '8px', textAlign: 'right', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>Staked LEEF</th>
                  </tr>
                </thead>
                <tbody>
                  {currentEntries.map((entry) => (
                    <tr key={entry.rank}>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>{entry.rank}</td>
                      <td style={{ padding: '8px', textAlign: 'left', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>{entry.username}</td>
                      <td style={{ padding: '8px', textAlign: 'right', borderBottom: `1px solid ${theme.borderColor}`, color: theme.textColor }}>{entry.staked}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                marginTop: '20px',
                flexWrap: 'wrap',
              }}>
                {Array.from({ length: Math.ceil(leaderboard.length / entriesPerPage) }).map((_, index) => (
                  <button
                    key={index}
                    onClick={() => paginate(index + 1)}
                    style={{
                      margin: '5px',
                      padding: '5px 10px',
                      backgroundColor: currentPage === index + 1 ? theme.buttonBackground : 'transparent',
                      color: currentPage === index + 1 ? theme.buttonText : theme.textColor,
                      border: `1px solid ${theme.borderColor}`,
                      borderRadius: '5px',
                      cursor: 'pointer',
                      fontSize: '12px',
                    }}
                  >
                    {index + 1}
                  </button>
                ))}
              </div>
            </>
          ) : (
            <p style={{ textAlign: 'center', color: theme.textColor }}>No leaderboard data available.</p>
          )}
        </div>
      )}

  {/* Reward Chart */}
      <div style={{
        width: '100%',
        backgroundColor: theme.cardBackground,
        padding: '15px',
        borderRadius: '8px',
        marginBottom: '20px',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
      }}>
        <h3 style={{
          color: theme.textColor,
          marginBottom: '15px',
          fontSize: '16px',
          textAlign: 'center',
        }}>LEEF Staking Rewards Received</h3>
        {rewardChartData.length > 0 ? (
          <>
            <div style={{ width: '100%', height: '250px' }}>
              <ResponsiveContainer>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <XAxis 
                    type="number" 
                    dataKey="timestamp" 
                    domain={['dataMin', 'dataMax']}
                    tickFormatter={(unixTime) => new Date(unixTime).toLocaleDateString()}
                    name="Time"
                    stroke={theme.textColor}
                    style={{ fontSize: '12px' }}
                  />
                  <YAxis 
                    type="number" 
                    dataKey="amount" 
                    name="Amount" 
                    unit=" LEEF" 
                    stroke={theme.textColor}
                    style={{ fontSize: '12px' }}
                  />
                  <Tooltip content={<CustomTooltip theme={theme} />} />
                  <Scatter name="Payout" data={rewardChartData} fill={theme.buttonBackground} />
                </ScatterChart>
              </ResponsiveContainer>
            </div>
            <div style={{ textAlign: 'center', marginTop: '15px', fontSize: '14px' }}>
              {rewardChartData.length > 0 && rewardChartData[rewardChartData.length - 1].total !== undefined ? (
                <>
                  <p style={{ fontWeight: 'bold', color: theme.textColor }}>
                    Total: {rewardChartData[rewardChartData.length - 1].total.toFixed(4)} LEEF
                  </p>
                  <p style={{ color: theme.textColor }}>
                    Average Daily Rate: {(rewardChartData[rewardChartData.length - 1].total / 7).toFixed(4)} LEEF/day
                  </p>
                </>
              ) : (
                <p style={{ color: theme.textColor }}>No transfer data available.</p>
              )}
            </div>
          </>
        ) : (
          <p style={{ color: theme.textColor, textAlign: 'center' }}>No transfer data available.</p>
        )}
      </div>
  {transactionInfo && (
        <div style={{
          position: 'fixed',
          top: '20px',
          right: '20px',
          backgroundColor: theme.cardBackground,
          color: theme.textColor,
          padding: '15px',
          borderRadius: '8px',
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
          zIndex: 1000,
          maxWidth: '300px',
        }}>
          <h4 style={{ marginBottom: '10px' }}>Transaction Confirmed</h4>
          <p><strong>Type:</strong> {transactionInfo.type}</p>
          <p><strong>Amount:</strong> {transactionInfo.amount}</p>
          {transactionInfo.room && <p><strong>Room:</strong> {transactionInfo.room}</p>}
          <p style={{ 
            wordBreak: 'break-all', 
            marginBottom: '10px'
          }}>
            <strong>Transaction ID:</strong> {transactionInfo.transactionId}
          </p>
          <a 
            href={`https://waxblock.io/transaction/${transactionInfo.transactionId}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'inline-block',
              padding: '8px 12px',
              backgroundColor: theme.buttonBackground,
              color: theme.buttonText,
              textDecoration: 'none',
              borderRadius: '4px',
              fontSize: '14px',
              textAlign: 'center',
              transition: 'background-color 0.3s',
            }}
            onMouseOver={(e) => e.target.style.backgroundColor = theme.buttonBackgroundHover}
            onMouseOut={(e) => e.target.style.backgroundColor = theme.buttonBackground}
          >
            View on WAXBlock
          </a>
        </div>
      )}
    <div className="App">
       {session && <TelegramUserID session={session} />}
    </div>

{/*LEEF PASSIVE REWARD*/}
<div style={{
  width: '100%',
  backgroundColor: theme.highlightBackground,
  padding: '20px',
  borderRadius: '8px',
  marginBottom: '20px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
}}>
  <h3 style={{ 
    color: theme.textColor, 
    marginBottom: '15px',
    fontSize: '18px',
    textAlign: 'center',
  }}>LEEF Rewards Dashboard</h3>
  
  <div style={{
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '15px',
    marginBottom: '20px',
  }}>
    <div style={{
      backgroundColor: theme.cardBackground,
      padding: '15px',
      borderRadius: '8px',
      textAlign: 'center',
    }}>
      <h4 style={{ 
        color: theme.textColor,
        marginBottom: '10px',
        fontSize: '14px',
      }}>Unclaimed Rewards</h4>
      <p style={{ 
        color: theme.textColor,
        fontSize: '18px',
        fontWeight: 'bold',
      }}>{rewardInfo.unclaimed_reward}</p>
    </div>
    
    <div style={{
      backgroundColor: theme.cardBackground,
      padding: '15px',
      borderRadius: '8px',
      textAlign: 'center',
    }}>
      <h4 style={{ 
        color: theme.textColor,
        marginBottom: '10px',
        fontSize: '14px',
      }}>Available in Hopper</h4>
      <p style={{ 
        color: theme.textColor,
        fontSize: '18px',
        fontWeight: 'bold',
      }}>{rewardInfo.available_balance || '0.0000 LEEF'}</p>
    </div>
  </div>

  <div style={{
    backgroundColor: theme.cardBackground,
    padding: '15px',
    borderRadius: '8px',
    marginBottom: '15px',
  }}>
    <h4 style={{ 
      color: theme.textColor,
      marginBottom: '10px',
      fontSize: '16px',
      textAlign: 'center',
    }}>Your Rewards</h4>
    
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginBottom: '15px',
      padding: '10px',
      backgroundColor: theme.highlightBackground,
      borderRadius: '5px',
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <span style={{ color: theme.textColor }}>Your Stake:</span>
        <span style={{ color: theme.textColor, fontWeight: 'bold' }}>
          {formattedStakedBalance}
          {hasNFTBonus && 
            <span style={{ color: '#4CAF50', marginLeft: '5px' }}>(+10% NFT Bonus)</span>
          }
        </span>
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}>
        <span style={{ color: theme.textColor }}>Stake Share:</span>
<span style={{ color: theme.textColor, fontWeight: 'bold' }}>
  {((parseFloat(formattedStakedBalance?.split(' ')[0] || 0) / 
    parseFloat(totalStaked?.split(' ')[0] || 1)) * 100).toFixed(2)}%
</span>
      </div>
    </div>

    {parseFloat(rewardInfo.unclaimed_reward) > 0 && (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '15px',
      }}>
        <button 
          onClick={handleClaim}
          style={{
            padding: '12px 24px',
            backgroundColor: theme.buttonBackground,
            color: theme.buttonText,
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
            fontSize: '16px',
            fontWeight: 'bold',
            transition: 'transform 0.2s ease',
          }}
          onMouseOver={e => e.currentTarget.style.transform = 'scale(1.05)'}
          onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
        >
          Claim Rewards
        </button>
      </div>
    )}
  </div>


</div>

{/* LEEF Ad System */}
<div style={{
  width: '100%',
  backgroundColor: theme.cardBackground,
  padding: '15px',
  borderRadius: '8px',
  marginTop: '20px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
}}>
  <h3 style={{ 
    color: theme.textColor, 
    marginBottom: '15px',
    fontSize: '16px',
    textAlign: 'center'
  }}>LEEF Ad System</h3>
  
  <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '15px' }}>
    <button
      onClick={() => setActiveAdTab('view')}
      style={{
        padding: '5px 10px',
        backgroundColor: activeAdTab === 'view' ? theme.buttonBackground : 'transparent',
        color: activeAdTab === 'view' ? theme.buttonText : theme.textColor,
        border: `1px solid ${theme.borderColor}`,
        borderRadius: '5px 0 0 5px',
        cursor: 'pointer',
      }}
    >
      View Ad
    </button>
    <button
      onClick={() => setActiveAdTab('submit')}
      style={{
        padding: '5px 10px',
        backgroundColor: activeAdTab === 'submit' ? theme.buttonBackground : 'transparent',
        color: activeAdTab === 'submit' ? theme.buttonText : theme.textColor,
        border: `1px solid ${theme.borderColor}`,
        borderLeft: 'none',
        cursor: 'pointer',
      }}
    >
      Submit Ad
    </button>

  </div>

  {activeAdTab === 'view' && <LeefAd theme={theme} />}
  {activeAdTab === 'submit' && <SubmitAd session={session} theme={theme} onSubmit={() => setActiveAdTab('view')} />}

</div>
    </div>
  );
};

export default App;
