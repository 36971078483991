import React, { useState, useEffect, useCallback } from 'react';

// Mock API function to check registration status
const checkRegistration = async (telegramId, session) => {
  try {
    if (!session) {
      throw new Error("WAX session not available");
    }
    const result = await session.client.v1.chain.get_table_rows({
      code: 'leefregister',
      scope: telegramId,
      table: 'telegram',
      limit: 1
    });
    console.log("Registration check result:", result);
    if (result.rows.length > 0) {
      return { isRegistered: true, waxAccount: result.rows[0].username };
    } else {
      return { isRegistered: false, waxAccount: null };
    }
  } catch (error) {
    console.error('Error checking Telegram registration:', error);
    throw error;
  }
};

const TelegramLoginButton = ({ botName, onAuth }) => {
  useEffect(() => {
    console.log("TelegramLoginButton: Setting up Telegram widget");
    const script = document.createElement('script');
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.async = true;
    script.setAttribute('data-telegram-login', botName);
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-auth-url', 'about:blank');
    script.setAttribute('data-request-access', 'write');
    
    window.onTelegramAuth = (user) => {
      console.log("Telegram auth successful", user);
      onAuth(user);
    };
    script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
    
    script.onload = () => {
      console.log("TelegramLoginButton: Telegram script loaded successfully");
    };

    script.onerror = () => {
      console.error("TelegramLoginButton: Failed to load Telegram script");
    };

    setTimeout(() => {
      const container = document.getElementById('telegram-login-button');
      if (container) {
        container.appendChild(script);
        console.log("TelegramLoginButton: Script appended to container");
      } else {
        console.error("TelegramLoginButton: Container not found");
      }
    }, 100);

    return () => {
      console.log("TelegramLoginButton: Cleaning up Telegram widget");
      const container = document.getElementById('telegram-login-button');
      if (container && container.firstChild) {
        container.removeChild(container.firstChild);
      }
      delete window.onTelegramAuth;
    };
  }, [botName, onAuth]);

  return <div id="telegram-login-button"></div>;
};

const registerTelegramUser = async (telegramId, session) => {
  try {
    if (!session || !session.actor) {
      throw new Error("WAX session not available or actor not set");
    }
    const action = {
      account: 'leefregister',
      name: 'reguser',
      authorization: [{ actor: session.actor, permission: 'active' }],
      data: {
        user: session.actor,
       id: Number(telegramId)
      },
    };
    console.log('Sending registration action:', action);
    const result = await session.transact({ actions: [action] });
    console.log('Registration transaction result:', result);
    return true;
  } catch (error) {
    console.error('Error registering Telegram user:', error);
    throw error;
  }
};

const TelegramLogin = ({ session }) => {
  const [telegramData, setTelegramData] = useState(null);
  const [isRegistered, setIsRegistered] = useState(null);
  const [waxAccount, setWaxAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    console.log("TelegramLogin: Component mounted");
    return () => {
      console.log("TelegramLogin: Component will unmount");
    };
  }, []);

  const handleAuth = useCallback(async (user) => {
    console.log("Captured Telegram auth data:", user);
    setTelegramData(user);
    setIsLoading(true);
    setError(null);
    try {
      const { isRegistered, waxAccount } = await checkRegistration(user.id, session);
      setIsRegistered(isRegistered);
      setWaxAccount(waxAccount);
    } catch (error) {
      setError("Failed to check registration status: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [session]);
  
  const handleRegister = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await registerTelegramUser(telegramData.id, session);
      const { isRegistered, waxAccount } = await checkRegistration(telegramData.id, session);
      setIsRegistered(isRegistered);
      setWaxAccount(waxAccount);
    } catch (error) {
      setError("Failed to register: " + error.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log("Current telegramData state:", telegramData);
  console.log("Is user registered:", isRegistered);

  return (
    <div style={{ fontFamily: 'Arial, sans-serif', maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <h1 style={{ textAlign: 'center', color: '#0088cc' }}>Telegram Account Link</h1>
      {!telegramData ? (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <TelegramLoginButton botName="ggametestbot" onAuth={handleAuth} />
        </div>
      ) : isLoading ? (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          Checking registration status...
        </div>
      ) : (
        <div style={{ backgroundColor: '#f0f0f0', padding: '20px', borderRadius: '8px', marginTop: '20px' }}>
          <h2 style={{ color: '#0088cc' }}>Linked Telegram Account </h2>
          <p><strong>Name:</strong> {telegramData.first_name} {telegramData.last_name}</p>
          <p><strong>Username:</strong> {telegramData.username}</p>
          <p><strong>Telegram ID:</strong> {telegramData.id}</p>
          <p><strong>Auth Date:</strong> {new Date(parseInt(telegramData.auth_date) * 1000).toLocaleString()}</p>
          <p><strong>Registration Status:</strong> {isRegistered ? 'Registered' : 'Not Registered'}</p>
          {!isRegistered && (
            <button 
              style={{
                backgroundColor: '#0088cc',
                color: 'white',
                padding: '10px 20px',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                fontSize: '16px',
                marginTop: '10px'
              }}
              onClick={handleRegister}
            >
              Register Now
            </button>
          )}
          {error && (
            <p style={{ color: 'red', marginTop: '10px' }}>{error}</p>
          )}
         
        </div>
      )}
    </div>
  );
};

export default TelegramLogin;
